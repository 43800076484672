<template>
  <div class="search-input">
    <!-- Search input -->
    <label :for="id" class="basic-label search-label">
      <img v-if="searchIcon" :src="displayedIcon" class="search-icon" alt="search icon"/>
      <input type="text"
             :id="id"
             :name="id"
             :placeholder="placeholder"
             :data-cy="`cy-${id}`"
             :disabled="isDisabled"
             autocomplete="off"
             @keyup="handleKey"
             @input="handleInput"
             @focus="() => updateDisplayedIcon(iconTypes.active)"
             @focusout="() => updateDisplayedIcon(iconTypes.inactive)"
             v-model="input"
             class="basic-input search-field"/>
      <!--      @input="handleInput"-->

      <!-- Cancel/Clear search -->
      <img v-if="input"
           @click="clearSearch()"
           src="../../../assets/icons/svg/ic_close_secondary.svg"
           class="clear-search"
           alt="clear"/>

      <!-- Search result count -->
      <span v-if="resultCount !== null" class="result-indicator">{{ searchResultText }}</span>
    </label>

    <!-- Results -->
    <!-- Available results -->
    <div v-if="includeSuggestions && areResultsDisplayed && searchResults.length" class="results-wrapper">
      <SearchResult v-for="(result, index) in searchResults"
                    :key="result.id"
                    @select="selectResultItem"
                    :is-selected="index === result.id"
                    :item="result"
                    :icon="require('../../../assets/icons/svg/ic_add_dark_grey.svg')"
                    class="result-item"/>
    </div>
    <!-- No results to be displayed (When results are active, the search is active and there are no results) -->
    <div v-if="includeSuggestions && areResultsDisplayed && input && !searchResults.length"
         class="results-wrapper no-results-wrapper">
      <p class="no-results-text">No results for '{{ input }}'</p>
    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from "@vue/composition-api";
import SearchResult from "@/components/partials/search/SearchResult";
import {addListener, removeListener} from "../../../utils/globals";

const iconTypes = {
  active: 'active',
  inactive: 'inactive'
}

export default {
  name: "SearchInputField",
  components: {
    SearchResult
  },
  props: {
    // Basic
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },

    // Behavior
    closeAfterSelection: {
      type: Boolean,
      required: false,
      default: true
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    autoSearch: {
      type: Boolean,
      required: false,
      default: false
    },

    // Suggestions
    includeSuggestions: {
      type: Boolean,
      required: false,
      default: true
    },

    // Results
    searchResults: {
      type: Array,
      required: false,
      default: () => []
    },
    resultCount: {
      type: Number,
      required: false,
      default: null
    },


    // Icons
    searchIcon: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  emits: ['search', 'select-item', 'clear-search'],
  setup(props, {emit}) {
    // console.log('created')
    // onUnmounted(() => {
    //   console.log('unmounted')
    // })
    /** Search input **/
    const input = ref('');

    function handleInput(event) {
      if (props.autoSearch) {
        search(input.value);
      }
    }

    function handleKey(event) {
      if (event.key === 'Enter') {
        search(input.value);
      }
    }

    function clearSearch() {
      input.value = '';
      search('');

      // if (props.closeAfterSelection) {
      toggleResults(false);
      // }
    }

    function focus() {
      document.getElementById(props.id).focus();
    }

    /** Search results **/
    const areResultsDisplayed = ref(true);

    function toggleResults(value = null) {
      if (props.includeSuggestions) {
        areResultsDisplayed.value = value === null ? !areResultsDisplayed.value : value;
        setClickListener();
      }
    }

    const searchResultText = computed(() => {
      const count = props.resultCount;
      let text = `${props.resultCount} search result`;
      if (count > 1) {
        return text + 's';
      }
      return text;
    });

    watch(() => props.searchResults, (newVal) => {
      if (newVal.length) {
        toggleResults(true);
      }
    });

    function selectResultItem(item) {
      // clearSearch();
      selectItem(item);
    }

    function search(value) {
      emit('search', value);
    }

    function selectItem(value) {
      emit('select-item', value);

      if (props.closeAfterSelection) {
        toggleResults(false);
      }
    }

    /** Search input icons **/
    const searchIcons = {
      active: require('../../../assets/icons/svg/ic_search_active.svg'),
      inactive: require('../../../assets/icons/svg/ic_search_inactive.svg'),
    }
    const displayedIcon = ref(searchIcons.inactive);

    function updateDisplayedIcon(type) {
      displayedIcon.value = searchIcons[type];
    }

    /** UI helpers **/
    function setClickListener() {
      areResultsDisplayed.value ? addListener('click', clickListener) : removeListener('click', clickListener);
    }

    function clickListener(event) {
      const target = event.target; // Get the target of the click event.

      // If the user clicked on the dropdown or the input field, the dropdown should remain open.
      if (!(target.id === props.id || target.classList.contains('results-wrapper') || target.classList.contains('result-item'))) {
        toggleResults(false);
      }
    }

    return {
      iconTypes,

      /** Search input **/
      input,
      handleInput,
      handleKey,
      clearSearch,
      focus,

      /** Search results **/
      areResultsDisplayed,
      selectResultItem,
      searchResultText,

      /** Icon **/
      searchIcons,
      displayedIcon,
      updateDisplayedIcon
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/forms";

.search-input {
  position: relative;
}

.search-label {
  position: relative;
}

.search-icon {
  @include position(absolute, $top: 50%, $left: rem(15));
  transform: translateY(-50%);
  height: rem(24);
  width: rem(24);
}

.clear-search {
  @include position(absolute, $top: 50%, $right: rem(17));
  @include hover-active-pointer();
  transform: translateY(-50%);
  height: rem(16);
  width: rem(16);
}

.result-indicator {
  @include position(absolute, $top: 50%, $right: rem(56));
  color: var(--gray-light-03);
  font-size: rem(14);
  letter-spacing: 0;
  line-height: rem(22);
  transform: translateY(-50%);
}

.search-field {
  height: 100%;
  min-height: rem(52);
  padding-left: rem(49);
}

// Results
.results-wrapper {
  @include position-elevation(absolute, $top: calc(100% + rem(8)), $right: 0, $left: 0, $elevation: 1); // Top: value + padding
  background-color: white;
  border: rem(1) solid var(--gray-light-04);
  border-radius: rem(4);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.25);
  padding: rem(8);
}

.no-results-wrapper {
  align-items: center;
  display: flex;
  height: rem(92);
  justify-content: center;

  .no-results-text {
    color: var(--gray-light-03);
    font-family: "Open Sans";
    font-size: rem(14);
    line-height: rem(19);
    text-align: center;
  }
}
</style>
