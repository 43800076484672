<template>
  <button :disabled="disabled"
          :class="{'button-disabled': disabled}"
          :type="type"
          class="d-button button-main">
    <!-- Button text -->
    <span v-if="text">{{ text }}</span>

    <!-- Button icon -->
    <img v-if="icon" :src="icon" class="button-icon" alt=""/>
  </button>
</template>

<script>
export default {
  name: "DButton",
  props: {
    text: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    icon: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.d-button {
  align-items: center;
  display: flex;
  justify-content: center;

  .button-icon {
    margin-left: rem(13);
    width: rem(24);
  }
}
</style>
