<template>
  <div @click="$emit('select', item)" :class="{'is-selected': isSelected}" class="search-result">
    <p class="text">{{item.name}}</p>

    <img :src="icon" class="icon" alt=""/>
  </div>
</template>

<script>
export default {
  name: "SearchResult",
  props: {
    item: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    icon: {
      type: String,
      required: true,
    }
  },
  emits: ['select'],
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/layout.shared";

.search-result {
  @include hover-active-pointer();
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: rem(8);

  .text {
    color: var(--black-light);
    font-weight: 600;
    font-size: rem(16);
  }

  .icon {
    height: rem(16);
    width: rem(16);
  }

  @include hover-active-pointer {
    background-color: var(--gray-main);
    border-radius: rem(5);
  }
}

.is-selected {
  border-radius: rem(5);
  background-color: var(--gray-main);
}
</style>
